import { Upload, message } from 'antd'
import axios from 'axios'
import Image from 'next/image'
import { useState } from 'react'
import imageCompression from 'browser-image-compression';
import { useTranslation } from 'next-i18next'
import { API_BASE_URL } from '../../constants';

const options = {
  maxSizeMB: 5, // Giới hạn kích thước tối đa của ảnh sau khi nén (5MB ở đây)
  maxWidthOrHeight: 800, // Giới hạn chiều rộng hoặc chiều cao tối đa sau khi nén (800px ở đây)
  useWebWorker: true // Sử dụng Web Worker để nén ảnh (tùy chọn)
};

const UploadImage = ({ text, handleSetUrl, defaultValue }) => {
  const { t  } = useTranslation('common')

  const [imageUrl, setImageUrl] = useState(defaultValue || null)
  const [loading, setLoading] = useState(false)

  const handleFileChange = async (file) => {
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      message.error(t('upload_image.accept'))
      return false
    }

    try {
      setLoading(true)
      const formData = new FormData()
      const compressedImage = await imageCompression(file, options);
      formData.append('file', compressedImage)

      const response = await axios.post(
        `${API_BASE_URL}/api/v1/upload-image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      const imageUrl = response?.data?.data
      if (imageUrl) {
        setImageUrl(imageUrl)
        message.success(t('upload_image.success'))
        handleSetUrl(imageUrl)
      } else {
        message.error(t('upload_image.fail'))
      }
    } catch (error) {
      message.error(t('upload_image.fail'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Upload
      accept=".png,.jpeg,.jpg"
      showUploadList={false}
      beforeUpload={handleFileChange}
    >
      <div className="input-cccd">
        {loading ? (
          <div className="img-uploader">
            {t('upload_image.uploading')}
          </div>
        ) : imageUrl ? (
          <div className="img-uploader">
            <Image
              src={imageUrl}
              alt={t('upload_image.imageChoose')}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
              width={268}
              height={123}
            />
          </div>
        ) : (
          <label className="btn-upload" htmlFor="input-up">
            <svg
              width="13"
              height="25"
              viewBox="0 0 13 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.34 5.82V3.68H3.3V0.72H5.44V3.68H8.4V5.82H5.44V8.78H3.3V5.82H0.34Z"
                fill="#222222"
              />
            </svg>
            {text}
          </label>
        )}
      </div>
    </Upload>
  )
}

export default UploadImage
