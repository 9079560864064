import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'

const IntroduceSimM2M = () => {
  const { t } = useTranslation('mToM')
  const [isShowYoutube, setIsShowYoutube] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsShowYoutube(true)
    }, 5000)
  })

  return (
    <>
      <div className="introduce container">
        <div className="text-introduce">
          <div
            className="heading"
            dangerouslySetInnerHTML={{ __html: t('introduce.title') }}
          ></div>
          <p
            className="content-introduce"
            dangerouslySetInnerHTML={{ __html: t('introduce.description') }}
          ></p>
          {/* <p className='content-introduce'>
        {t('introduce.description-1')}
        </p>
        <p className='content-introduce1'>
        {t('introduce.description-2')}
        </p> */}
        </div>
        <div className="image-introduce">
          {/* <img src="/images/landingPageM2M/introduce-5g.png" alt="5G" /> */}
          {isShowYoutube && (
            <iframe
              className="responsive-iframe"
              width="520"
              height="292.5"
              src="https://www.youtube.com/embed/s806OaA0hLU"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </div>
    </>
  )
}

export default IntroduceSimM2M
