import React from 'react'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'

function OurPartners(props) {
  const { t } = useTranslation('mToM')

  return (
    <div className="container">
      <div className="our-partners">
        <div className='our-partners-title'>{t('our-partners.title')}</div>
        <div className="line"></div>
        <div className="partner-list">
          <div className="partner-item">
            <img
              src="/images/landingPageM2M/viettel-partner-v3.png"
              alt="viettel-logo"
              width={240}
              height={84}
            />
          </div>

          <div className="partner-item">
            <img
              src="/images/landingPageM2M/vinaphone-partner-v2.png"
              alt="vinaphone-logo"
              width={240}
              height={84}
            />
          </div>

          <div className="partner-item">
            <img
              src="/images/landingPageM2M/mobifone-partner-v2.png"
              alt="mobifone-logo"
              width={240}
              height={84}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default OurPartners
