import React from 'react'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'

const ServicesProvided = () => {
  const { t } = useTranslation('mToM')

  return (
    <div className="container">
      <div className="services-provided">
        <h4>{t('services-provided.title')}</h4>
        <p className="services-provided-description">{t('services-provided.description')}</p>
        <div className="services-list">
          <div className="service-item">
            <Image
              className="service-item-icon"
              src="/images/landingPageM2M/service-provided-one.png"
              alt="service-icon"
              width={376}
              height={230}
            />
            <div className="service-item-name">{t('services-provided.services-one')}</div>
          </div>

          <div className="service-item">
            <Image
              className="service-item-icon"
              src="/images/landingPageM2M/service-provided-two.png"
              alt="service-icon"
              width={376}
              height={230}
            />
            <div className="service-item-name">{t('services-provided.services-two')}</div>
          </div>

          <div className="service-item">
            <Image
              className="service-item-icon"
              src="/images/landingPageM2M/service-provided-three.png"
              alt="service-icon"
              width={376}
              height={230}
            />
            <div className="service-item-name">{t('services-provided.services-three')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesProvided
