/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import {HOTLINE, PHONE_NUMBER, SOCIAL} from '../../constants'
import Image from 'next/image'
import {useTranslation} from 'next-i18next'

const AboutUs = () => {
  const { t } = useTranslation('mToM')

  return (
    <div className="container">
      <div className="contact-block">
        <div className="title">{t('about_us.title')}</div>
        <div className="panel">
          <div className="content">
            <div className="main-content">
              <div className="company-name">{t('about_us.company')}</div>
              <div className="company-location">{t('about_us.address')}</div>

              <div className="phone-comp common-margin">
                <Image
                  src="/images/common/phone.svg"
                  alt="phone"
                  width={28}
                  height={28}
                />
                <a href={`tel:+84${HOTLINE.slice(1)}`}>{HOTLINE}</a>
                {/* <a href={`tel:+84${PHONE_NUMBER.slice(1)}`}>{PHONE_NUMBER}</a> */}
              </div>
              <div className="mail-comp ">
                <Image
                  src="/images/common/email.svg"
                  width={28}
                  height={28}
                  alt="email"
                />
                <a href="mailto:support@simplus.vn">
                  support@simplus.vn
                </a>
              </div>
              <div className="list-social common-margin">
                <a
                  href={SOCIAL.tiktok}
                  target="_blank"
                  className="social-item"
                  rel="noreferrer"
                >
                  <Image
                    src="/images/common/tiktok.svg"
                    width={28}
                    height={28}
                    alt="tiktok"
                  />
                </a>
                <a
                  href={SOCIAL.yt}
                  target="_blank"
                  className="social-item"
                  rel="noreferrer"
                >
                  <Image
                    src="/images/common/youtube.svg"
                    width={28}
                    height={28}
                    alt="youtube"
                  />
                </a>
                <a
                  href={SOCIAL.fb}
                  target="_blank"
                  className="social-item"
                  rel="noreferrer"
                >
                  <Image
                    src="/images/common/facebook.svg"
                    width={28}
                    height={28}
                    alt="facebook"
                  />
                </a>
                <a
                  href={SOCIAL.zalo}
                  target="_blank"
                  className="social-item"
                  rel="noreferrer"
                >
                  <Image
                    src="/images/common/zalo.svg"
                    width={28}
                    height={28}
                    alt="zalo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
