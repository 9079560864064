import React, {useEffect} from 'react';
import Image from "next/image";
import {useTranslation} from "next-i18next";
import {Select} from "antd";
import {formatNumber, formatUSDNumber} from "../../../utils/currencyConverter";


const MAPPING_PERIOD_NUMBER = {
  'one_month': 1,
  'three_month': 3,
  'six_month': 6,
  'twelve_month': 12,
}

function SlickCarouselM2MItem({dataItem, isTravelAbroad, t, i18n}) {
  const [periodList, setPeriodList] = React.useState([]);
  const [currentPrice, setCurrentPrice] = React.useState(0);
  const [selectedPeriod, setSelectedPeriod] = React.useState(null);

  useEffect(() => {
    if (dataItem?.period_price) {
      const currentPeriodList = Object.entries(dataItem?.period_price).sort((a, b) => MAPPING_PERIOD_NUMBER[a[0]] - MAPPING_PERIOD_NUMBER[b[0]]) // Sắp xếp theo thứ tự tăng dần của thời gian
        .filter((item) => item[1]) // Lọc ra những gói cước không có giá hoặc giá = 0

      setCurrentPrice(currentPeriodList[0][1])   // Giá gói cước mặc định khi hiển thị component
      setSelectedPeriod(currentPeriodList[0])

      setPeriodList(currentPeriodList)
    }
    else {
      setPeriodList([])
    }
  }, [dataItem]);

  const renderCurrency = () => {
    const language = i18n.language
    if (language === 'en') {
      return '$'
    }
    if (language === 'zh') {
      return '¥'
    }
    return 'VND'
  }

  const handleChangePeriod = (value) => {
    const selectedPeriod = periodList.find((item) => item[0] === value)
    console.log('selectedPeriod', selectedPeriod)
    setSelectedPeriod(selectedPeriod)
    setCurrentPrice(selectedPeriod[1])
  }

  return (
    <div className="carousel-item">
      <div className="image-section">
        <div className="image-block">
          <div className="image-item">
            <Image
              src={dataItem?.image}
              alt="sim-sing"
              width={338}
              height={338}
            />
          </div>
        </div>
      </div>
      <div className="content-section">
        <div className="carousel-title">{dataItem?.title}</div>
        <div
          className="carousel-sub-title"
          dangerouslySetInnerHTML={{ __html: dataItem?.subtitle }}
        />
        <div
          className="carousel-content"
          dangerouslySetInnerHTML={{ __html: dataItem?.description?.replace(/&nbsp;/g, ' ') }}
        />

        {/* {
          periodList?.length && (
            <div className={'period-title'}>{t('package_information')}</div>)
        } */}

        {
          periodList?.length &&
          <div className={'select-period-block'}>
            <Select onChange={handleChangePeriod} value={selectedPeriod} className={'select-box-period'}>
              {periodList.map((item, index) => (
                <Select.Option value={item[0]} key={item[0]}>{`${dataItem?.title} - ${MAPPING_PERIOD_NUMBER[item[0]]} ${t('month')}`}</Select.Option>
              ))}

            </Select>
            <div className={'product-price'}>{formatUSDNumber(currentPrice)} {renderCurrency()}</div>
          </div>

        }
        {isTravelAbroad ? (
          <button className="contact-button">
            {t(isTravelAbroad ? 'tourists-to-vietnam.contact' : 'tourists-to-vietnam.order')}
          </button>
        ) : (
          <a href="#sim-order" style={{ textDecoration: 'none' }}>
            <button className="contact-button">
              {t(isTravelAbroad ? 'tourists-to-vietnam.contact' : 'tourists-to-vietnam.order')}
            </button>
          </a>
        )}
      </div>
    </div>
  );
}

export default SlickCarouselM2MItem;
