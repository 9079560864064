import React from 'react'
import CustomerCommentsCarousel from './customerCommentsCarousel'
import Image from 'next/image'
import {useTranslation} from 'next-i18next'

// const REVIEWS_DATA = [
//   {
//     customerImage: '/images/landingPageM2M/customer.png',
//     name: 'John Doe',
//     review:
//       'The service I felt was very good, the staff and doctors were friendly and provided very clear information.',
//     rating: '/images/icon/rating.svg',
//   },
//   {
//     customerImage: '/images/landingPageM2M/customer.png',
//     name: 'John Doe',
//     review:
//       'The service I felt was very good, the staff and doctors were friendly and provided very clear information.',
//     rating: '/images/icon/rating.svg',
//   },
//   {
//     customerImage: '/images/landingPageM2M/customer.png',
//     name: 'John Doe',
//     review:
//       'The service I felt was very good, the staff and doctors were friendly and provided very clear information.',
//     rating: '/images/icon/rating.svg',
//   },
//   {
//     customerImage: '/images/landingPageM2M/customer.png',
//     name: 'John Doe',
//     review:
//       'The service I felt was very good, the staff and doctors were friendly and provided very clear information.',
//     rating: '/images/icon/rating.svg',
//   },
//   {
//     customerImage: '/images/landingPageM2M/customer.png',
//     name: 'John Doe',
//     review:
//       'The service I felt was very good, the staff and doctors were friendly and provided very clear information.',
//     rating: '/images/icon/rating.svg',
//   },
// ]

function CustomerComments(props) {
  const { t } = useTranslation('mToM')

  const translatedComments = t('customer-comments.commentsList', { returnObjects: true })

  return (
    <div className="container">
      <div className="customer-comments">
        <div className='customer-comments-title'>{t('customer-comments.title')}</div>
        <Image
          className="quotation-icon"
          src="/images/icon/quotation.svg"
          alt="quotation"
          width={221}
          height={168}
        />
        <CustomerCommentsCarousel data={translatedComments} />
      </div>
    </div>
  )
}

export default CustomerComments
