/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import Image from 'next/image'

const PrevButton = (props) => (
  <button
    {...props}
    className="button-arrow button-arrow-left"
    type="button"
    aria-label="Prev"
    style={{ left: props.left }}
  >
    {/* <span className="arrow left" /> */}
    <Image
      src="/images/common/Chevron-Left-Gray.svg"
      alt="Chevron-Left"
      width={50}
      height={50}
    />
  </button>
)

const NextButton = (props) => (
  <button
    {...props}
    className="button-arrow button-arrow-right"
    type="button"
    aria-label="Next"
    style={{ right: props.right }}
  >
    {/* <span className="arrow right" /> */}
    <Image
      src="/images/common/Chevron-Right-Gray.svg"
      alt="Chevron-Right"
      width={50}
      height={50}
    />
  </button>
)

const CustomerCommentsCarousel = ({ data }) => {
  // const { t } = useTranslation("touristsToVN");
  const [innerWidth, setInnerWidth] = useState(0)
  useEffect(() => {
    setInnerWidth(window.innerWidth)
    window.addEventListener('resize', () => {
      setInnerWidth(window.innerWidth)
    })
  }, [])
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow:
      !innerWidth || innerWidth > 1000 ? 3 : innerWidth > 650 ? 2 : 1,
    slidesToScroll: 1,
    prevArrow: (
      <PrevButton
        left={
          !innerWidth || innerWidth > 1000 ? -70 : innerWidth > 650 ? -50 : -10
        }
      />
    ),
    nextArrow: (
      <NextButton
        right={
          !innerWidth || innerWidth > 1000 ? -70 : innerWidth > 650 ? -50 : -10
        }
      />
    ),
  }
  return (
    <div className="slick-carousel">
      <Slider {...settings}>
        {Array.isArray(data) &&
          data.length > 0 &&
          data.map((d, index) => (
            <div key={index} className="carousel-item">
              <div className="customer-review-item">
                <div className="content-section">
                  <div className="customer-avatar">
                    <Image
                      className="customer-image"
                      key={`${d.customerImage} index`}
                      src={d?.customerImage}
                      alt="customer"
                      width={392}
                      height={262}
                      loading={'eager'}
                    />
                    <div className="customer-name-role">
                      <div className="customer-name">{d.name}</div>
                      {/* <div className="customer-role">{d.role}</div> */}
                    </div>
                  </div>
                  <div className="customer-review">{d.review}</div>
                  <div className="customer-rating">
                    <Image
                      src={d.rating}
                      key={`${d.rating} index`}
                      alt="rating"
                      width={68}
                      height={11}
                      loading={'eager'}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  )
}

export default CustomerCommentsCarousel
