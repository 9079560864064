import { Button, Form, Input, Modal, Spin, message } from 'antd'
import { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import callApiTemporary from '../../utils/callApiTemporary'
import Image from 'next/image'
import { HOTLINE } from '../../constants'

function PopupCheckProductInformation({ isOpen, setIsOpen }) {
  const { t } = useTranslation('checkProduct')
  const [form] = Form.useForm()

  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const [productInformation, setProductInformation] = useState(null)

  const checkStatus = async (serial) => {
    setStatus('')
    setProductInformation(null)
    try {
      setLoading(true)
      const response = await callApiTemporary(
        `/api/v1/products/serial/${serial?.trim()}`
      )
      setStatus(response?.data?.status || 'not-found')
      setProductInformation(response?.data)
    } catch (error) {
      message.error(t('try_later'))
    } finally {
      setLoading(false)
    }
  }

  const generateProductInformation = () => {
    return (
      <div className="product-information-block">
        <div className="image-section">
          <img
            src={productInformation?.accessory?.image_urls[0]}
            alt="product-photo"
          />
        </div>
        <div className="information-section">
          <div className="information-section-item">
            <div className="key">{`${t('product_name')} :`}</div>
            <div className="value"> {productInformation?.accessory?.name}</div>
          </div>
          <div className="information-section-item display-inline">
            <div className="key">{`${t('sku')}: `}</div>
            <div className="value">
              {' '}
              {productInformation?.accessory?.sku_code}
            </div>
          </div>
          <span className="bulkhead"> | </span>
          <div className="information-section-item display-inline">
            <div className="key">{`${t('category')}: `}</div>
            <div className="value">
              {' '}
              {productInformation?.accessory?.category_name}
            </div>
          </div>
          <div className="information-section-item">
            <div className="key">{`${t('guarantee_duration')}: `}</div>
            <div className="value">
              {' '}
              {`${productInformation?.accessory?.guarantee_duration} ${t(
                'month'
              )}`}{' '}
            </div>
          </div>
          {(productInformation?.status === 'active' ||
            productInformation?.status === 'run_out_guarantee') && (
            <div className="information-section-item">
              <div className="key">{`${t(
                'remaining_guarantee_duration'
              )}: `}</div>
              <div className="value-red">
                {' '}
                {productInformation?.status === 'run_out_guarantee'
                  ? `{${t('run_out_guarantee')}`
                  : `${calculateTime()} ${t('month')}`}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const onFinish = (values) => {
    checkStatus(values?.serial)
  }

  const generateResult = (status) => {
    switch (status) {
      case 'inactive':
        return (
          <div>
            {generateProductInformation()}
            <div className="not-activated-status common-status">
              {t('not_activate')}
            </div>
          </div>
        )
      case 'active':
        return (
          <div>
            {generateProductInformation()}
            <div className="activated-status common-status">
              <div className="activated-status-child">{t('activated')}</div>
            </div>
          </div>
        )
      case 'run_out_guarantee':
        return (
          <>
            {generateProductInformation()}
            <div className="activation-failed-status common-status">
              {t('run_out_guarantee_capitalize')}
            </div>
          </>
        )
      case 'not-found':
        return (
          <div className="not-found-product" style={{ paddingTop: '27px' }}>
            <div className="img-not-found">
              <Image
                src="/images/landingPageM2M/product-not-found.png"
                alt="service-icon"
                width={294}
                height={150}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: t('not_found_product', { HOTLINE }),
                }}
              />
            </div>
          </div>
        )
      default:
        return
    }
  }

  const calculateTime = () => {
    if (
      !productInformation?.warranted_at ||
      !productInformation?.accessory?.guarantee_duration
    ) {
      return 0
    }
    const monthsDifference =
      (new Date().getFullYear() -
        new Date(productInformation?.warranted_at).getFullYear()) *
        12 +
      (new Date().getMonth() -
        new Date(productInformation?.warranted_at).getMonth())
    return productInformation?.accessory?.guarantee_duration - monthsDifference
  }

  useEffect(() => {
    form.resetFields()
    setStatus('')
    setProductInformation(null)
  }, [isOpen])

  return (
    <Modal
      width="600px"
      open={isOpen}
      footer={null}
      onCancel={() => setIsOpen(false)}
    >
      <div className="popup-activation-status">
        <div className="title">{t('check_product_info')}</div>
        <div className="search-block">
          <Form onFinish={onFinish} form={form}>
            <Form.Item
              name="serial"
              rules={[
                {
                  required: true,
                  message: t('please_enter_serial'),
                },
              ]}
            >
              <Input
                size="small"
                prefix={<SearchOutlined />}
                allowClear
                placeholder={t('enter_serial')}
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="checked-button"
                type="primary"
                htmlType="submit"
              >
                {t('submit')}
              </Button>
            </Form.Item>
          </Form>
        </div>
        {loading ? (
          <Spin style={{ marginTop: '20px' }} />
        ) : (
          generateResult(status)
        )}
      </div>
    </Modal>
  )
}

export default PopupCheckProductInformation
