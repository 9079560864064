export function currencyConverter(amount, withSymBol = true) {
  if (amount && !isNaN(amount)) {
    return (
      amount.toLocaleString('vi-VN', {
        minimumFractionDigits: 0,
      }) + (withSymBol ? 'đ' : '')
    )
  }
  return amount
}

export function formatNumber(number, n, x) {
  const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? '\\.' : '$'})`
  try {
    return number.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,')
  } catch (e) {
    return number
  }
}

export function formatUSDNumber(number) {
  try {
    return number.toLocaleString('en-US', { maximumFractionDigits: 3 });
  } catch (e) {
    return number
  }
}
