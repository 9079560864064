import axios from 'axios'
import Cookies from 'js-cookie'
import { ACCESS_TOKEN } from '../../constants'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get(ACCESS_TOKEN)

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  (error) => {
    // handle 401 authen
    // router.push('/login')
    return Promise.reject(error)
  }
)

export default axiosInstance
