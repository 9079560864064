import React, { useState } from 'react'
import Image from 'next/image'
import { ID_LINE, ID_SKYPE, ID_WECHAT, ID_WHATSAPP } from '../../constants'
import { useTranslation } from 'next-i18next'

const Contact = (props) => {
  const { setIsCallInfoModal } = props

  const { t } = useTranslation('mToM')

  return (
    <div className="contact">
      <div className="container">
        <div className="contact-banner">
          <p
            className="service-invitation"
            dangerouslySetInnerHTML={{
              __html: t('contact.service_invitation'),
            }}
          />
          <p
            className="service-description"
            dangerouslySetInnerHTML={{
              __html: t('contact.service_description'),
            }}
          />
          <div className="contact-btn" onClick={() => setIsCallInfoModal(true)}>{t('contact.contact_now')}</div>
        </div>
        <div className="list-contact">
          <a
            className="contact-item"
            target="_blank"
            href={`https://join.skype.com/invite/${ID_SKYPE}`}
            rel="noreferrer"
          >
            <Image
              src="/images/common/skype.svg"
              alt="skype"
              width={40}
              height={40}
            />
          </a>
          <a
            className="contact-item"
            target="_blank"
            href={`https://api.whatsapp.com/send/?phone=${ID_WHATSAPP}&text&type=phone_number&app_absent=0`}
            rel="noreferrer"
          >
            <Image
              src="/images/common/viber.svg"
              alt="viber"
              width={40}
              height={40}
            />
          </a>
          <a
            className="contact-item"
            target="_blank"
            href={`https://web.wechat.com/${ID_WECHAT}`}
            rel="noreferrer"
          >
            <Image
              src="/images/common/we-chat.svg"
              alt="we-chat"
              width={40}
              height={40}
            />
          </a>
          <a
            className="contact-item"
            target="_blank"
            href={`https://line.me/ti/p/-${ID_LINE}`}
            rel="noreferrer"
          >
            <Image
              src="/images/common/line.svg"
              alt="skype"
              width={40}
              height={40}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Contact
