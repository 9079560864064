import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  message,
} from 'antd'
import { useTranslation } from 'next-i18next'
import {
  API_BASE_URL_TEMPORARY,
  HOTLINE,
  M2M_TYPE,
  PHONE_NUMBER, regexPhoneNumber,
} from '../../constants'
import Image from 'next/image'
import callApiTemporary from '../../utils/callApiTemporary'
import axios from 'axios'

const bot = {
  TOKEN: '6409313141:AAHVXnRfj019lBg9TZcBq9p9BMGlnACiZzM',
  chatID: '-821313934',
}
const URL_SCRIPT_GOOGLE =
  'https://script.google.com/macros/s/AKfycbxscrt3VGkJoAkRsV5u5-EV0ihYu4OlFe_4FLkrDfTZwxNtXknDqdZBQ5DHMRJdRE21/exec'

const {Option} = Select
const phoneNumber = '0888000800'
const SimOrder = (props) => {
  const { isCallInfoModal, setIsCallInfoModal } = props
  const {t, i18n} = useTranslation('mToM')
  const language = i18n.language
  const [dataOptions, setDataOptions] = useState([])
  const [options, setOptions] = useState([])
  const [isCreatedTicketSupportModal, setIsCreatedTicketSupportModal] =
    useState(false)
  const [formTicketSupport] = Form.useForm()
  const [form] = Form.useForm()
  const [availableOptions, setAvailableOptions] = useState(options)
  const [mobile, setMobile] = useState(false)
  const [isTicketSupportValid, setIsTicketSupportValid] = useState(false)

  // useEffect(() => {
  //   setMobile(isMobile)
  // }, [])

  const [isSuccessModal, setIsSuccessModal] = useState(false)


  const [isSending, setIsSending] = useState(false)
  const [isShowSelector, setShowSelector] = useState(false)
  const saveGGSheet = (data) => {
    fetch(URL_SCRIPT_GOOGLE, {
      method: 'POST',
      body: JSON.stringify(data),
      mode: 'no-cors',
      headers: {'Content-Type': 'application/json'},
    })
      .then((response) => console.log(response))
      .catch((error) => console.log(error))
  }
  const onFinish = (values) => {
    setIsSending(true)
    const url = `https://api.telegram.org/bot${bot.TOKEN}/sendMessage`
    const message = `Họ Tên: ${values.name}\nĐiện thoại: ${
      values.phone
    }\nĐịa chỉ: ${values.address}\nGhi chú: ${
      values.note ?? ''
    }\nSản phẩm:${values.products.reduce(
      (prevValue, currentValue) =>
        `${prevValue} \n ${currentValue.simType} |  số lượng: ${currentValue.amount}`,
      ''
    )}`

    const params = {chat_id: bot.chatID, text: message}
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {'Content-Type': 'application/json'},
    })
      .then((response) => response.json())
      .then(() => {
        saveGGSheet(values)
        setIsSending(false)
        showModal()
        form.resetFields()
        form.setFieldValue('products', [{ simType: undefined, amount: 1 }])
        setAvailableOptions(
          dataOptions.map((item) => ({
            label: item.title ? item.title[language] : '',
            value: item.title ? item.title[language] : '',
          }))
        )
      })
      .catch((error) => {
        setIsSending(false)
        console.error(error)
      })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  const handleCall = useCallback(() => {
    if (mobile) {
      window.open(`tel:${phoneNumber}`)
    } else {
      setIsCallInfoModal(true)
    }
  }, [mobile])
  const handleCancel = () => {
    setIsCallInfoModal(false)
  }

  const showModal = () => {
    setIsSuccessModal(true)
  }
  const handleSimTypeChange = (index, selectedValue) => {
    const currentValues = form.getFieldValue('products') || []
    const updatedValues = [...currentValues]
    updatedValues[index].simType = selectedValue
    form.setFieldsValue({products: updatedValues})
    const selectedOptions = updatedValues.map((value) => value.simType)
    const remainingOptions = options.filter(
      (option) => !selectedOptions.includes(option.value)
    )
    setAvailableOptions(remainingOptions)
  }

  const handleRemove = (index) => {
    const values = form.getFieldValue('products')
    values.splice(index, 1)
    form.setFieldsValue({products: values})
    const selectedOptions = values.map((item) => item.simType)
    const remainingOptions = options.filter(
      (option) => !selectedOptions.includes(option.value)
    )
    setAvailableOptions(remainingOptions)
  }

  const getData = async () => {
    try {
      const response = await callApiTemporary(`/api/v1/landing-products`, {
        params: {
          limit: 50,
          page: 1,
          product_type: M2M_TYPE,
        },
      })
      const data = response?.data?.items || []
      setDataOptions(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setShowSelector(true)
    }, 5000)
    getData().then()
  }, [])

  useEffect(() => {
    if (dataOptions && dataOptions.length > 0) {
      const rs = dataOptions.map((item) => ({
        label: item.title ? item.title[language] : '',
        value: item.title ? item.title[language] : '',
      }))
      setOptions(rs)
      setAvailableOptions(rs)
    }
    form.resetFields()
  }, [dataOptions, language])

  const onFinishTicketSupport = async (values) => {
    setIsSending(true)
    try {
      await axios.post(`${API_BASE_URL_TEMPORARY}/api/v1/tickets`, {
        ...values,
        title: 'Ticket Hỗ trợ từ M2M',
        description: `Họ Tên: ${values?.name}<br>Điện thoại: ${values?.phone_number}<br>Sản phẩm: ${values?.product}`,
      })
      formTicketSupport.resetFields()
      setIsCreatedTicketSupportModal(true)
      setIsCallInfoModal(false)
    } catch (error) {
      message.error(t('error_create_ticket_processing'))
    } finally {
      setIsSending(false)
    }
  }

  const onValuesChange = () => {
    setIsTicketSupportValid(
      checkObjectValidity(formTicketSupport.getFieldsValue())
    )
  }

  const onFieldsChange = () => {
    setIsTicketSupportValid(
      checkObjectValidity(formTicketSupport.getFieldsValue())
    )
  }

  const checkObjectValidity = (obj) => {
    const requiredKeys = ['name', 'phone_number', 'product', 'isChecked']

    for (const key of requiredKeys) {
      if (
        !(key in obj) ||
        obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === '' ||
        obj[key] === false
      ) {
        return false
      }
    }
    return true
  }

  return (
    <div className="container" id="sim-order">
      <div className="call-us">
        <button type="button" onClick={handleCall}>
          {t('sim-order.call_us_to_order')}
        </button>
      </div>
      <div className="sim-order">
        <div className="header-block">
          <div className="header-title">
            <Image
              src="/images/common/send.svg"
              alt="send-icon"
              width={29}
              height={29}
            />
            <span className="ml-2">{t('sim-order.order')}</span>
          </div>
          <div className="header-content">{t('sim-order.description')}</div>
        </div>

        <div className="input-block">
          <Form
            form={form}
            name="basic"
            style={{maxWidth: 484, width: '100%'}}
            initialValues={{products: [{simType: undefined, amount: 1}]}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            disabled={isSending}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: t('sim-order.message_input_your_name'),
                },
              ]}
            >
              <Input placeholder={t('sim-order.name')}/>
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: t('sim-order.message_input_your_phone'),
                },
                {
                  pattern: regexPhoneNumber,
                  message: t('sim-order.message_input_valid_phone'),
                },
              ]}
            >
              <Input placeholder={t('sim-order.phone')}/>
            </Form.Item>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: t('sim-order.message_input_your_address'),
                },
              ]}
            >
              <Input placeholder={t('sim-order.address')}/>
            </Form.Item>
            <Form.Item name="note">
              <Input.TextArea rows={3} placeholder={t('sim-order.note')}/>
            </Form.Item>
            <div className="item-section">
              <Form.List name="products">
                {(fields, {add, remove}) => (
                  <>
                    {fields.map((field, index) =>
                      isShowSelector ? (
                        <Space
                          key={field.key}
                          align="baseline"
                          style={{ width: '100%' }}
                        >
                          <Form.Item
                            style={{ width: '100%' }}
                            noStyle
                            shouldUpdate={() => true}
                          >
                            {() => (
                              <Form.Item
                                {...field}
                                name={[field.name, 'simType']}
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'sim-order.message_choose_product'
                                    ),
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  placeholder={t('sim-order.product')}
                                  onChange={(value) =>
                                    handleSimTypeChange(index, value)
                                  }
                                >
                                  {availableOptions.map((item) => (
                                    <Option
                                      key={item?.value}
                                      value={item?.value}
                                    >
                                      {item?.label}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )}
                          </Form.Item>
                          <Form.Item
                            style={{ width: 75 }}
                            {...field}
                            name={[field.name, 'amount']}
                            rules={[{ required: true, message: ' ' }]}
                            initialValue={1}
                          >
                            <InputNumber
                              style={{ width: '100%' }}
                              size="large"
                              min={1}
                              maxLength={5}
                              defaultValue={1}
                            />
                          </Form.Item>
                          {fields?.length !== 1 && (
                            <div>
                              <a
                                className="cancel-icon"
                                onClick={() => handleRemove(index)}
                              >
                                <Image
                                  src="/images/common/icon-cancel.png"
                                  alt="cancel-icon"
                                  width={31}
                                  height={28}
                                />
                              </a>
                            </div>
                          )}
                        </Space>
                      ) : (
                        <div key={field.key} />
                      )
                    )}

                    {fields?.length < 5 && (
                      <Form.Item>
                        <Button
                          size="large"
                          type="dashed"
                          onClick={() => add()}
                          block
                        >
                          {t('sim-order.addProduct')}
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.List>
              <Form.Item className="submit-block">
                <button type="submit" disabled={isSending}>
                  {' '}
                  {t('sim-order.orderNow')}
                </button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      <Modal
        width="358px"
        open={isSuccessModal || isCreatedTicketSupportModal}
        footer={null}
        closable={false}
        onCancel={() => {
          setIsSuccessModal(false)
          setIsCreatedTicketSupportModal(false)
        }}
        centered
      >
        <div className="common-modal modal-register-success">
          <Image
            src="/images/landingPage/simplus-success.png"
            alt="phone"
            width={294}
            height={155}
          />
          <div className="title">
            {isCreatedTicketSupportModal
              ? t('sim-order.modalCallInfo.thank')
              : t('sim-order.modalSuccess.title')}
          </div>
          <div className="content">
            <div>
              {isCreatedTicketSupportModal
                ? t('sim-order.modalCallInfo.keep_in_touch')
                : t('sim-order.modalSuccess.content')}
            </div>
            {!isCreatedTicketSupportModal && (
              <div style={{ marginTop: '6px' }}>{t('sim-order.modalSuccess.thank')}</div>
            )}
          </div>

          <button
            disabled={isSending}
            className="cancel-btn"
            onClick={() => {
              setIsSuccessModal(false)
              setIsCreatedTicketSupportModal(false)
              // window.location.reload()
            }}
          >
            {t('sim-order.modalSuccess.cancel')}
          </button>
        </div>
      </Modal>
      <Modal
        width="600px"
        open={isCallInfoModal}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        centered
      >
        <div className="modal-call-info">
          <div style={{ fontSize: '18px', fontWeight: '600', marginTop: '12px' }}>
            {t('sim-order.modalCallInfo.title')}
          </div>
          <Form
            layout="vertical"
            name="messageForm"
            form={formTicketSupport}
            onFinish={onFinishTicketSupport}
            disabled={isSending}
            onValuesChange={onValuesChange}
            onFieldsChange={onFieldsChange}
          >
            <Form.Item
              label={t('sim-order.modalCallInfo.name')}
              name="name"
              style={{ marginTop: '24px' }}
              rules={[
                { required: true, message: t('sim-order.message_input_your_name') },
              ]}
            >
              <Input placeholder={t('sim-order.name')} />
            </Form.Item>

            <Form.Item
              label={t('sim-order.modalCallInfo.phone')}
              name="phone_number"
              rules={[
                { required: true, message: t('sim-order.message_input_your_phone') },
                {
                  pattern: regexPhoneNumber,
                  message: t('sim-order.message_input_valid_phone'),
                },
              ]}
            >
              <Input placeholder={t('sim-order.phone')} />
            </Form.Item>

            <Form.Item name="product" label={t('sim-order.modalCallInfo.product')}>
              <Select size="large" placeholder={t('sim-order.product')}>
                {options.map((item) => (
                  <Option key={item?.value} value={item?.label}>
                    {item?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <div className="commit">
              <Form.Item
                name="isChecked"
                valuePropName="checked"
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(),
                  },
                ]}
              >
                <Checkbox className="text-commit">
                  {t('sim-order.modalCallInfo.confirm_register')}
                </Checkbox>
              </Form.Item>
            </div>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ borderRadius: '10px', marginTop: '15px' }}
                disabled={!isTicketSupportValid}
              >
                {t('sim-order.modalCallInfo.request-support')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default SimOrder
