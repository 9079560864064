import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'

const Banner = () => {
  const {t} = useTranslation('mToM')
  const [innerWidth, setInnerWidth] = useState(0)
  useEffect(() => {
    setInnerWidth(window.innerWidth)
    window.addEventListener('resize', () => {
      setInnerWidth(window.innerWidth)
    })
  }, [])
  return (
    <div className="main-banner">
      {innerWidth !== 0 && innerWidth <= 650 && (
        <div className="img-mb-banner">
          <Image
            src="/images/landingPageM2M/bg-main.png"
            alt="background"
            width={1226}
            height={612}
            priority={true}
          />
        </div>
      )}
      <div className="container banner">
          {innerWidth > 650 && (
              <Image
                src="/images/landingPageM2M/bg-main.png"
                alt="background"
                width={1296}
                height={647}
                priority={true}
                className="img-banner"
              />
          )}

          <h1 dangerouslySetInnerHTML={{ __html: t('banner.slogan') }} />
          <div className="sub-slogan" dangerouslySetInnerHTML={{__html: t('banner.sub_slogan')}}>
            {/* <br />⭐ {t('OUR_BEST_SELLER')} ⭐ */}
          </div>
          <div className="btn btn-order-now">
            <a href="#sim-order">{t('banner.order_now')}</a>
          </div>
          {/* <div className="info-call-list">
            <div className="info-call">
              <div className="call-head">60’</div>
              <div>{t('Local_Calls')}</div>
            </div>
            <div className="info-call">
              <div className="call-head">100</div>
              <div>{t('Local_SMS')}</div>
            </div>
            <div className="info-call">
              <div className="call-head">30’</div>
              <div>{t('International_Calls')}</div>
            </div>
          </div> */}
        </div>
      </div>
  )
}

export default Banner
