/* eslint-disable react/jsx-key */
import { Dropdown, Modal, message } from 'antd'
import Cookies from 'js-cookie'
import Image from 'next/image'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import {
  API_BASE_URL_TEMPORARY,
} from '../../../constants'
import LanguageButton from '../languageButton'
import PopupRegisterForTravelSim from '../popupRegisterForTravelSim'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import PopupCheckActivationStatus from '../popupCheckActivationStatus'
import PopupCheckProductInformation from './../popupCheckProductInformation';

const Header = ({ isHeaderLandingPage, isMobileDevice, keyword, isM2M }) => {
  const { t } = useTranslation('landingPage')
  const [isLoadingRegister, setIsLoadingRegister] = useState(false)
  const [isOpenRegister, setIsOpenRegister] = useState(false)
  const [isOpenActivationStatus, setIsOpenActivationStatus] = useState(false)
  const [isOpenProdcutInformation, setIsOpenProdcutInformation] = useState(false)

  const items = [
    {
      key: '1',
      label: (
        <a
          style={{ textAlign: 'center', lineHeight: '30px', display: 'block' }}
          onClick={() => setIsOpenRegister(true)}
        >
          {t('Request_Sim_Activation')}
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          style={{
            textAlign: 'center',
            lineHeight: '30px',
            display: 'block',
            padding: '0 20px',
          }}
          onClick={() => setIsOpenActivationStatus(true)}
        >
          {t('check_sim_activation_status')}
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a
          style={{
            textAlign: 'center',
            lineHeight: '30px',
            display: 'block',
            padding: '0 20px',
          }}
          onClick={() => setIsOpenProdcutInformation(true)}
        >
          {t('check_product_information')}
        </a>
      ),
    },
  ]

  const handleRegisterTravelSim = async (registerTravelSim) => {
    setIsLoadingRegister(true)
    try {
      await axios.post(
        `${API_BASE_URL_TEMPORARY}/api/v1/landing-products/sim-register-infos-m2m`,
        {
          ...registerTravelSim,
        }
      )
      message.success(t('successfully_sim_activation'))
      setIsOpenRegister(false)
    } catch (error) {
      message.error(
        (error?.response?.data?.message[0] ===
          'arrived_date must be before leave_date' &&
          t('departure_before_leave')) ||
          (error?.response?.data?.message?.includes('E10035') &&
            t('serial_already_registered')) ||
          t('error_processing')
      )
    } finally {
      setIsLoadingRegister(false)
    }
  }

  return (
    <header>
      <div className="header header-landing">
        <Link href="/">
          <div className="logo-img">
            <img
              src="/images/common/logo-v8.png"
              alt="logo"
            />
          </div>
        </Link>
        <div className="container">
          <div className="header-comp">
            <Dropdown
              menu={{ items }}
              overlayClassName="dropdown-activate-sim"
            >
              <div style={{cursor: 'pointer'}} onClick={(e) => e.preventDefault()}>
                <div className="header-btn burger-btn">
                  <div className="icon-burger">
                    <Image
                      src="/images/common/icon-category.svg"
                      alt="icon-category"
                      width={0}
                      height={0}
                    />
                  </div>
                </div>
              </div>
            </Dropdown>
            <LanguageButton isM2M={isM2M} />
          </div>
        </div>
      </div>
      <PopupRegisterForTravelSim
        isOpen={isOpenRegister}
        loading={isLoadingRegister}
        setIsOpen={setIsOpenRegister}
        handleRegisterTravelSim={handleRegisterTravelSim}
      />
      <PopupCheckActivationStatus
        isOpen={isOpenActivationStatus}
        setIsOpen={setIsOpenActivationStatus}
      />
      <PopupCheckProductInformation
        isOpen={isOpenProdcutInformation}
        setIsOpen={setIsOpenProdcutInformation}
      />
    </header>
  )
}

export default Header
