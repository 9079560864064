import React, {useEffect, useState} from 'react';

function useGetInnerWidth(props) {
  const [innerWidth, setInnerWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);
  return innerWidth;
}

export default useGetInnerWidth;
