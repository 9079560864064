import React from 'react'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'

const WhereCanYouFindUs = () => {
  const { t } = useTranslation('mToM')

  return (
    <div className="container">
      <div className="find-us">
        <h4>{t('find-us.title')}</h4>

        <div className="find-us-content">
          <div className="find-us-video">
            <Image
              src="/images/landingPageM2M/video.png"
              alt="video"
              width={600}
              height={338}
            />
          </div>
          <div className="find-us-description">{t('find-us.description')}</div>
        </div>
      </div>
    </div>
  )
}

export default WhereCanYouFindUs
