import React, { useEffect, useState } from 'react'
import Footer from './footer'
import Header from './header'
import { Table, DatePicker } from 'antd';

Table.defaultProps = {
  locale: {
    emptyText: 'Không có dữ liệu'
  }
};

DatePicker.defaultProps = {
  placeholder: ''
};

const Layout = ({ children, isHeaderLandingPage, isMobileDevice, keyword, isM2M }) => {

  return (
    <div>
      <Header
        isHeaderLandingPage={isHeaderLandingPage}
        isMobileDevice={isMobileDevice}
        keyword={keyword}
        isM2M={isM2M}
      />
      {children}
      <Footer isMobileDevice={isMobileDevice} />
    </div>
  )
}

export default Layout
