import React, { useState, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import LanguageSwitchLink from './LanguageSwitchLink'
import Image from 'next/image'

const listLang = [
  {
    id: 'vi',
    name: 'Vietnamese',
  },
  {
    id: 'en',
    name: 'English',
  },
  {
    id: 'zh',
    name: 'Chinese',
  },
]

const LanguageButton = ({isM2M}) => {
  const { t, i18n } = useTranslation('common')
  const currentLanguage = i18n.language

  const [open, setOpen] = useState(false)

  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <div
      className="button-lang-cont"
    >
      <div className="button-lang" onClick={toggleOpen}>
        <div className="selected-lang">{t('header.language')}</div>
        <Image
          className="flag"
          width={30}
          height={24}
          src={`/images/common/${currentLanguage}.svg`}
          alt={`${currentLanguage}`}
        />
        <Image
          src="/images/common/arrow-down.svg"
          alt="arrow-down"
          width={15}
          height={10}
        />
      </div>
      <div
        className="dropdown-lang"
        style={{ display: open ? 'block' : 'none' }}
        tabIndex={0}
        onBlur={() => setOpen(false)}
      >
        {listLang.map((item) => (
          <LanguageSwitchLink
            currentLanguage={currentLanguage}
            locale={item.id}
            item={item}
            key={item.id}
            setOpen={setOpen}
            isM2M={isM2M}
          />
        ))}
      </div>
    </div>
  )
}

export default LanguageButton
